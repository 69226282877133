// Core variables and mixins from Bootstrap
@import "../../custom";

.sub-nav-wrapper {

	.start-search {
		margin-right: 15px;
	}
}

.header-nav-drop {
	
	@include media-breakpoint-between(md,md) {
		margin-top: -3rem;
	}
}

.mini-border {
	background-color: #FFF;
	border-left: 0;
	border-top: 2px solid;
	margin-bottom: 0.5rem;

	a {
		padding: 0.5em 2em;
		display: block;
	}
}
.mini-border-blend{
	border-color:#1069c9;
}
.mini-border-asset-allocation {
	border-color:#983897;
}
.mini-border-fixed-income {
	border-color:#fd9827;
}
.mini-border-taxable-income {
	border-color:#ddba17;
}
.mini-border-sustainable {
	border-color:#60bb46;
}
.mini-border-alternatives {
	border-color:#778e9d;
}

/*----------Institutional Client Selection CSS----------*/
#client-selection-wrapper{
	padding-top: 10px;
	padding-bottom: 10px;
}
#client-selection-container{
	position: relative;
	background-color: #fff;
	border:1px solid #ccc;
	border-radius:5px;
	clear:both;
}

#client-selection-container h4{
	cursor:pointer;
	display:block;
	font-size:12px;
	margin:0;
	padding:8px;
}

#client-selection-container p{
	line-height:14px;
	margin:0;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	width:98%;
}

#client-selection-container h4 img{
	position:absolute;
	right:5px;
	top:15px;
}

#client-selection-container span{
	background:url("/static/img/institutional/content/select-sprite.png") no-repeat scroll right 0 transparent;
	display:block;
	height:24px;
	position:absolute;
	right:4px;
	top:4px;
	width:25px;
}

#client-selection-container:hover span{
	background-position:right -41px;
}

#client-selection-container.active span{
	background-position:right -82px;
}

#client-selection-list-container{
	background-color:#fff;
	box-shadow:1px 3px 10px #213D55;
	height:225px;
	padding:0;
	position:absolute;
	right:0;
	top:30px;
	width:100%;
	z-index:10;
}

#client-selection-list {
	background-color:#fff;
	height:185px;
	list-style-type:none;
	margin:0;
	overflow-x:hidden;
	overflow-y:scroll;
	padding:0;
	width:inherit;
}

#client-selection-list li{
	color:#000 !important;
	font-size:11px;
	padding:5px 5px 5px 8px;
}

#client-selection-list li:hover{
	background-color:#597CA4;
	cursor:pointer;
}

#client-selection-list li a{
	background:none repeat scroll 0 0 rgba(0, 0, 0, 0);
	border:medium none;
	color:#000;
	font-size:11px;
	line-height:inherit;
	padding:0;
	white-space:normal;
}

#client-selection-list li a:hover{
	text-decoration:none;
}

#client-selection-container #client-search{
	border:1px solid #999;
	bottom:5px;
	left:5px;
	padding:5px 7px;
	position:absolute;
	width:90%;
}

/*----------Cookie Banner Styles---------*/
#cookie-banner{
	background:#f0f0f0;
	border-bottom:1px solid #ccc;
	color:#333;
	font-family:sans-serif;
	font-size:17px;
}
#cookie-banner .container{
	padding-bottom:18px;
	padding-top:18px;
}
#cookie-banner span.cookie-text{
	margin-right:20px;
}

/*----------Main Header overrides---------*/
#institutional-account-summary nav.institutional-nav {
	margin-bottom:0;
}
#institutional-account-summary #location-share, #institutional-portfolio-details #location-share, #institutional-reports #location-share, #institutional-reset-password #location-share{
	display:none;
}
.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

/*----------Institutional Modal Content CSS----------*/
#legal-content-container .modal-body, #ucits-legal-content-container .modal-body{
	max-height:400px;
	overflow-y:scroll;
	margin-top:40px;
}

/*----------Institutional Account Summary CSS----------*/

#account-summary-wrapper .date-cat {
	padding-left:24px;
	display:block;
	font-size:11px;
	width:100%;
	text-overflow:ellipsis;
	overflow:hidden;
	white-space:nowrap;
}
#account-summary-wrapper .icon {
	margin-top:-10px;
	margin-right:5px;
	float:left;
}
#account-summary-wrapper #reports-container li {
	margin-bottom:0;
}
#account-summary-wrapper #reports-container li a {
	text-overflow:ellipsis;
	overflow:hidden;
	white-space:nowrap;
	padding-left:24px;
	width:100%;
	display:block;
	top:-8px;
	position:relative;
}
#account-summary-wrapper .view-all {
	line-height:26px;
}
#account-wrapper h4, #account-wrapper h4.single-account{
	background-image:none;
}

#account-wrapper h4.single-account{
	padding:0;
}

@media (min-width:768px){
	#account-summary-wrapper {
		padding-top: 30px;
	}
	#account-wrapper h4 .putnamicon-angle-down {
		font-size: 24px;
		margin-right: 6px;
		color: #3695f9;
		vertical-align: -3px;
	}
	#account-wrapper h4.active .putnamicon-angle-down:before {
		content:"\e641";
	}
	#account-wrapper h4 .col-sm-8{
		text-overflow:ellipsis;
		overflow:hidden;
		white-space:nowrap;
	}
}
.grey-bar {
	padding-top:10px;
	padding-bottom:10px;
	background-color:#EEE;
}
.grey-bar #client-name,
.grey-bar .total-assets {
	margin:0
}

#account-wrapper {
	position: relative;
}
#account-summary-wrapper .well {
	background-color: #FAFAFA;
	border-radius: 0;
}
#account-wrapper .acctName,
#account-wrapper .total-assets {
	cursor:pointer;
	position: relative;
}
#account-wrapper .acctName:before,
#account-wrapper .total-assets:before {
	position: absolute;
	display: block;
	content: "";
	width: 101%;
	width: calc(100% + 8px);
	height: 110%;
	height: calc(100% + 8px);
	top: -8px;
	left: -8px;
	background-color: #dee6f0;
	border-left: 7px solid #3399ff;
}
#account-wrapper .total-assets:before {
	background-color: #3399ff;
	border-left: 7px solid #1262b1;
}
#account-wrapper .total-assets {
	margin-bottom: 0;
	cursor: default;
	color: #FFF;
}
#account-wrapper:before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	top: 0;
	left: -8px;
	border-left: 7px solid #dee6f0;
}

#reports-container, #services-container{
	list-style-type:none;
}
#reports-container {
	height:160px;
	overflow-y:hidden;
}

/*----------Institutional Participant Transaction Table CSS----------*/
.portfolio-details-table-container .table-wrapper{
	width:100%;
}

[class^="icon-"],
[class*=" icon-"]{
	width:16px;
	height:16px;
	/*background-image:none;*/
}
td[class^="icon-"],
td[class*=" icon-"]{
	display:table-cell;
	background-repeat:no-repeat;
	background-position:3px 8px;
	padding-left:25px !important;
}
[class^="icon-social"],
[class*="icon-social"] {
	width:21px;
	height:21px;
	background-image:url("/static/img/individual/layout/icons-social.png");
}
.icon
.icon-{background:url("/static/img/institutional/file-extensions/other.png")}
.icon-pdf{background:url("/static/img/institutional/file-extensions/pdf.png")}
.icon-xls, .icon-xlsx{background:url("/static/img/institutional/file-extensions/xls.png")}
.icon-doc, .icon-docx{background:url("/static/img/institutional/file-extensions/doc.png")}
.icon-ppt, .icon-pptx{background:url("/static/img/institutional/file-extensions/ppt.png")}
.icon-mdb{background:url("/static/img/institutional/file-extensions/mdb.png")}
.icon-zip, .icon-rar{background:url("/static/img/institutional/file-extensions/zip.png")}
.icon-rtf, .icon-txt{background:url("/static/img/institutional/file-extensions/txt.png")}
.icon-png, .icon-jpg, .icon-gif, .icon-bmp, .icon-tif, .icon-jpg{background:url("/static/img/institutional/file-extensions/png.png")}


/* Overwrites breaking icon styles to reports table */
.report-name {
	width: auto;
	height: auto;
	line-height: inherit;
	background-position: 3px 16px !important;
}

/*----------Institutional Table Shading CSS----------*/
.holdings-table tbody{
	display:table-row-group;
	vertical-align:middle;
}

.holdings-table td.shadings{
	background-color:#eaedf0;
	padding:0;
	vertical-align:middle;
	width:100px;
}

.holdings-table td.shadings div.heatMap{
	background-color:#cbd6df;
	bottom:0px;
	left:0px;
	margin-bottom:0px;
	position:absolute;
	top:0px;
	z-index:80;
}

.holdings-table td.shadings div.heatMapWrapper{
	line-height:15px;
	margin-bottom:0px;
	min-height:27px;
	position:relative;
	text-align:left;
}

.holdings-table td.shadings div.heatMapText{
	margin-bottom:0px;
	padding:5px 5px 5px 5px;
	position:relative;
	z-index:100;
}


/*----------Investment Capabilities and Team----------*/

.product-highlights {
	ul li {
		margin-bottom: 10px !important;
	}
}
a.fixed-income-orange {
	color: #f0871d;
	font-weight: 600;
}
.border-left-orange {
	border-top: 4px solid #f0871d;
}
.border-left-blue {
	border-left: 8px solid #3f6aba;
}
.border-top-blue {
	border-top: 4px solid #3f6aba;
}
a.equity-blue {
	color: #3f6aba;
	font-weight: 600;
}
.border-left-purple {
	border-left: 8px solid #902473;
}
.border-top-purple {
	border-top: 4px solid #902473;
}
a.multi-asset-purple {
	color: #902473;
	font-weight: 600;
}
.border-left-green {
	border-left: 8px solid #60bb46;
}
.border-top-green, .border-growth{
	border-top:solid 4px #1ca605;
}
.blue-bg{
	background-color: #ECF4F9;
}
.white-bg{
	background-color: #fff;
}
.gray-bg{
	background-color: #eee;
}
a.growth-green {
	color: #60bb46;
	font-weight: 600;
}
.border {
	border: solid 1px #ccc;
}
.feat-img{
	float: right;
	padding: 0 0 0 10px;
}
li.feat {
	height: 100px;
	list-style: outside none disc;
	vertical-align: top;
}
.commentary-img {
	margin: 20px 0 10px 10px !important;
}
.solid{
	border-top:1px solid #ccc;
}
#institutional-product-details .highcharts-container {
	width: 100% !important;
	max-width: 100%;
}
#institutional-product-details div[id^="performance-chart-"] {
	float: none !important;
}
.fund-class-selector a{
	display: block;
	margin-bottom: 5px;
	text-decoration: none;
	background-color: #fff;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	position: relative;
	padding: 7px 10px 7px 20px;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
}
.fund-class-selector h2{
	margin-top: 13px;
}

.fund-class-selector a:hover{
	background-color: #f8f8f8;
	border-color: #bbb;
}
.fund-color{
	width: 10px;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
}
.fund-color.equity {
	background-color: #1069c9;
}
.fund-color.fixed-income {
	background-color: #fd9827;
}
.fund-color.risk-allocation {
	background-color: #983897;
}
.fund-color.retail-fund {
	background-color: #ddba17;
}
.team-block{
	background-color: #f1f3f5;
	border: 1px solid #d9dde3;
	overflow: hidden;
	margin: 0 0 10px 0;
	padding: 10px;
}
@media (min-width:768px){
	.team-block {
		margin: 0 15px 15px 0;
	}
}

/*----------Institutional Manager Styles----------*/
#manager-bios-list {
	list-style: none;

	.item {

		height: 115px;
		margin-bottom: 14px;
		text-decoration: none;

		.item-content {
			overflow: hidden;
			padding: 10px;
			border: solid 1px #ddd;
			background-color: #f1f3f5;
			background-color: #F4F4F4; // new
			border: 0;
			border-top: solid 3px #535353; // new
		}
	}

	.manager-info {
		padding-left: 15px;
	}
	.manager-name {
		line-height: 16px;
		color: $body-color; //New
	}
	.manager-title {
		color: #444;
	}
}

/*----------Institutional Accordian----------*/
.institutional-accordian h4 {
	background-image:url("/static/img/institutional/icon/icon_sprite_chevron.png");
	background-repeat:no-repeat;
	background-position:right 9px;
	cursor:pointer;
	position: relative;
}
.institutional-accordian h4.active {
	background-position:right -20px;
}

/*----------Institutional Literature----------*/
.lit-list li {
	max-width: 250px;
	margin-right: 20px;

	&.show-bullet {
		margin-left: 20px;
		list-style-type: disc;
	}
}

/*----------Institutional Contact Us----------*/
#institutional-contact h4 span.view-all{
	display:none;
}

@media (min-width:768px){
	#institutional-contact #services-container .col-sm-4 {
		width: 13% !important;
	}
}

/*----------Table Hover ----------*/
.hover-active{
	background-color:#F0F7FF;
}

.active-hover-cell{
	background-color:#d0e1f5
}

/*----------Strategies Bar Chart Table----------*/
#institutional-product-details tbody th{
	border-bottom:1px dotted #d6d6d6;
}



/*----------Institutional GDP NowCast and Risk Index code----------*/
.gray-block {
	background-color: #F2F3F4;
}
.gdp-risk-wrapper {
	display: block;
	line-height: 1.4em;
	background-color: #F2F3F4;

	@at-root a#{&} {
		color: #444;
		text-decoration: none;
	}
}
.gdp-risk-header {
	padding: 0.2em 0.8em;
	border-bottom: 1px solid #DDD;
}
.gdp-risk-body {
	padding: 0.6em 0.8em;
}
.gdp-risk-icon {
	font-size: 30px;
	vertical-align: -5px;
	margin-right: 4px;
	color: #00abbd;

	&.risk-red {
		color: #f58220;
	}
}
.arrow-up {
	color:#77B956;
	margin-right: 0.15em;
}
.arrow-down {
	color: red;
}
.risk-month {
	letter-spacing: 1px;
}
.risk-img {
	margin-left: 8px;
	max-width: 74px;
}



/*---------- Hilarious username dropdown hover hack ----------*/
#username-dropdown-wrapper {
	
	.dropdown-menu {

		&::after {
			content: "";
			width: 100%;
			height: 2px;
			position: absolute;
			top: -2px;
			left: 0;
		}
	}
}



/*---------- This makes things blue ----------*/
.text-blue {
    color: #317cc0;
}

/* Institutional fat nav changes */
.fat-nav {
	.nav-card {
		a {
			display: block;

			p {
				padding: 10px;
				margin-bottom: 0;
				background-color: #FFF;
			}
		}
	}
}